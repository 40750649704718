import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export class Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Uuid: any;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: Date;
  /** The `Byte` scalar type represents non-fractional whole numeric values. Byte can represent values between 0 and 255. */
  Byte: any;
};




export class Query {
  __typename?: 'Query';
  session?: Maybe<SessionQueryType>;
  users?: Maybe<UserQueryType>;
  appRoles?: Maybe<AppRoleQueryType>;
  roleFeatureMap?: Maybe<RoleFeatureMapQueryType>;
  userRoleMap?: Maybe<UserRoleMapQueryType>;
  roles?: Maybe<Array<Maybe<RoleIdentity>>>;
};


export class QueryRolesArgs {
  where?: Maybe<RoleIdentityFilterInput>;
};

export class AppRole {
  __typename?: 'AppRole';
  id: Scalars['Uuid'];
  roleName?: Maybe<Scalars['String']>;
  roleCode?: Maybe<Scalars['String']>;
  businessId: Scalars['Uuid'];
  description?: Maybe<Scalars['String']>;
  roleTypeId?: Maybe<Scalars['Uuid']>;
};

export class Business {
  __typename?: 'Business';
  id?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  tollFree?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  currency: Scalars['String'];
  businessTypeId: TenantTypes;
  industryId: Industries;
  state: States;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  isOwned: Scalars['Boolean'];
};

export class RoleFeatureData {
  __typename?: 'RoleFeatureData';
  featureId: Scalars['Uuid'];
  featureName?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
  isRead: Scalars['Boolean'];
  isWrite: Scalars['Boolean'];
  featureParentId?: Maybe<Scalars['Uuid']>;
};

export class RoleIdentity {
  __typename?: 'RoleIdentity';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export class SessionQueryType {
  __typename?: 'SessionQueryType';
  businesses?: Maybe<BusinessQueryType>;
  me?: Maybe<UserIdentity>;
};

export class UserIdentity {
  __typename?: 'UserIdentity';
  id?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  gender: Gender;
  pic?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  country?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  state: States;
};

export class UserRoleMap {
  __typename?: 'UserRoleMap';
  id: Scalars['Uuid'];
  userId?: Maybe<Scalars['String']>;
  roleId: Scalars['Uuid'];
  createdOn: Scalars['DateTime'];
  updatedOn: Scalars['DateTime'];
};

export class Mutation {
  __typename?: 'Mutation';
  session?: Maybe<SessionMutationType>;
  businesses?: Maybe<BusinessMutationType>;
  users?: Maybe<UserMutationType>;
  appRoles?: Maybe<AppRoleMutationType>;
};

export class SessionMutationType {
  __typename?: 'SessionMutationType';
  me?: Maybe<ProfileMutationType>;
  version?: Maybe<Scalars['String']>;
};

export enum ApplyPolicy {
  BeforeResolver = 'BEFORE_RESOLVER',
  AfterResolver = 'AFTER_RESOLVER'
}

export class UserQueryType {
  __typename?: 'UserQueryType';
  paginated?: Maybe<UserIdentityCollectionSegment>;
  first?: Maybe<UserIdentity>;
  userRoles?: Maybe<Array<Maybe<RoleIdentity>>>;
};


export class UserQueryTypePaginatedArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  states?: Maybe<Array<States>>;
  where?: Maybe<UserIdentityFilterInput>;
  order?: Maybe<Array<UserIdentitySortInput>>;
};


export class UserQueryTypeFirstArgs {
  userId: Scalars['Uuid'];
  where?: Maybe<UserIdentityFilterInput>;
};


export class UserQueryTypeUserRolesArgs {
  userId: Scalars['Uuid'];
  where?: Maybe<RoleIdentityFilterInput>;
};

export class AppRoleQueryType {
  __typename?: 'AppRoleQueryType';
  paginatedRoles?: Maybe<AppRoleCollectionSegment>;
  roles?: Maybe<Array<Maybe<AppRole>>>;
};


export class AppRoleQueryTypePaginatedRolesArgs {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppRoleFilterInput>;
  order?: Maybe<Array<AppRoleSortInput>>;
};


export class AppRoleQueryTypeRolesArgs {
  where?: Maybe<AppRoleFilterInput>;
  order?: Maybe<Array<AppRoleSortInput>>;
};

export class RoleFeatureMapQueryType {
  __typename?: 'RoleFeatureMapQueryType';
  paginatedRoleFeatureMap?: Maybe<Array<Maybe<RoleFeatureData>>>;
};


export class RoleFeatureMapQueryTypePaginatedRoleFeatureMapArgs {
  roleId?: Maybe<Scalars['Uuid']>;
  order?: Maybe<Array<RoleFeatureDataSortInput>>;
};

export class UserRoleMapQueryType {
  __typename?: 'UserRoleMapQueryType';
  userRoles?: Maybe<Array<Maybe<UserRoleMap>>>;
};


export class UserRoleMapQueryTypeUserRolesArgs {
  where?: Maybe<UserRoleMapFilterInput>;
  order?: Maybe<Array<UserRoleMapSortInput>>;
};

export enum TenantTypes {
  CementRetail = 'CEMENT_RETAIL',
  ShoesRetail = 'SHOES_RETAIL',
  Export = 'EXPORT',
  GroceryRetail = 'GROCERY_RETAIL',
  Generic = 'GENERIC'
}

export enum Industries {
  SoleProprietorShip = 'SOLE_PROPRIETOR_SHIP',
  Partnership = 'PARTNERSHIP',
  Corporation = 'CORPORATION'
}

export enum States {
  Undefined = 'UNDEFINED',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  InProgress = 'IN_PROGRESS',
  Hired = 'HIRED',
  Terminated = 'TERMINATED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Closed = 'CLOSED',
  ShortListed = 'SHORT_LISTED',
  InterviewScheduled = 'INTERVIEW_SCHEDULED'
}

export class BusinessQueryType {
  __typename?: 'BusinessQueryType';
  ownedBusinesses?: Maybe<Array<Maybe<Business>>>;
  associatedBusinesses?: Maybe<Array<Maybe<Business>>>;
};


export class BusinessQueryTypeOwnedBusinessesArgs {
  where?: Maybe<TenantFilterInput>;
};


export class BusinessQueryTypeAssociatedBusinessesArgs {
  where?: Maybe<TenantFilterInput>;
};

export enum Gender {
  Na = 'NA',
  Male = 'MALE',
  Female = 'FEMALE'
}


export class BusinessMutationType {
  __typename?: 'BusinessMutationType';
  create?: Maybe<Scalars['String']>;
  update?: Maybe<Scalars['String']>;
  archive?: Maybe<Scalars['String']>;
  restore?: Maybe<Scalars['String']>;
};


export class BusinessMutationTypeCreateArgs {
  model?: Maybe<CreateBusinessVmInput>;
};


export class BusinessMutationTypeUpdateArgs {
  model?: Maybe<UpdateBusinessVmInput>;
};


export class BusinessMutationTypeArchiveArgs {
  businessId: Scalars['Uuid'];
};


export class BusinessMutationTypeRestoreArgs {
  businessId: Scalars['Uuid'];
};

export class UserMutationType {
  __typename?: 'UserMutationType';
  assignRolesToUser?: Maybe<Scalars['String']>;
  syncActiveDirectoryUsers?: Maybe<Scalars['String']>;
  syncSingleUser?: Maybe<Scalars['String']>;
  add?: Maybe<Scalars['String']>;
  createProjectClientUser?: Maybe<Scalars['String']>;
  addRemoveProjectClientOwners?: Maybe<Scalars['String']>;
  update?: Maybe<Scalars['String']>;
  archive?: Maybe<Scalars['String']>;
  restore?: Maybe<Scalars['String']>;
};


export class UserMutationTypeAssignRolesToUserArgs {
  model?: Maybe<AssignUserRolesInput>;
};


export class UserMutationTypeSyncSingleUserArgs {
  userId: Scalars['Uuid'];
};


export class UserMutationTypeAddArgs {
  model?: Maybe<CreateUserInput>;
};


export class UserMutationTypeCreateProjectClientUserArgs {
  model?: Maybe<CreateProjectClientInput>;
};


export class UserMutationTypeAddRemoveProjectClientOwnersArgs {
  model?: Maybe<ProjectClientOwnersInput>;
};


export class UserMutationTypeUpdateArgs {
  model?: Maybe<UpdateUserInput>;
};


export class UserMutationTypeArchiveArgs {
  userId: Scalars['Uuid'];
};


export class UserMutationTypeRestoreArgs {
  userId: Scalars['Uuid'];
};

export class AppRoleMutationType {
  __typename?: 'AppRoleMutationType';
  create?: Maybe<Scalars['String']>;
  update?: Maybe<Scalars['String']>;
};


export class AppRoleMutationTypeCreateArgs {
  model?: Maybe<CreateAppRoleVmInput>;
  modelF?: Maybe<Array<Maybe<RoleFeatureVmInput>>>;
  modelU?: Maybe<Array<Maybe<UserRoleVmInput>>>;
};


export class AppRoleMutationTypeUpdateArgs {
  model?: Maybe<UpdateAppRoleVmInput>;
  modelF?: Maybe<Array<Maybe<UpdateRoleFeatureVmInput>>>;
  modelU?: Maybe<Array<Maybe<UpdateUserRoleVmInput>>>;
};

export class ProfileMutationType {
  __typename?: 'ProfileMutationType';
  update?: Maybe<Scalars['String']>;
  updatePassword?: Maybe<Scalars['String']>;
  closeAccount?: Maybe<Scalars['String']>;
  forgotPassword?: Maybe<Scalars['String']>;
};


export class ProfileMutationTypeUpdateArgs {
  model?: Maybe<UpdateProfileInput>;
};


export class ProfileMutationTypeUpdatePasswordArgs {
  model?: Maybe<UpdatePasswordInput>;
};


export class ProfileMutationTypeForgotPasswordArgs {
  email?: Maybe<Scalars['String']>;
};

export class RoleIdentityFilterInput {
  and?: Maybe<Array<RoleIdentityFilterInput>>;
  or?: Maybe<Array<RoleIdentityFilterInput>>;
  userRoles?: Maybe<ListFilterInputTypeOfUserRoleIdentityFilterInput>;
  id?: Maybe<StringOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  normalizedName?: Maybe<StringOperationFilterInput>;
  concurrencyStamp?: Maybe<StringOperationFilterInput>;
};

export class UserIdentityFilterInput {
  and?: Maybe<Array<UserIdentityFilterInput>>;
  or?: Maybe<Array<UserIdentityFilterInput>>;
  userProfileCompleted?: Maybe<BooleanOperationFilterInput>;
  tenantProfileCompleted?: Maybe<BooleanOperationFilterInput>;
  displayName?: Maybe<StringOperationFilterInput>;
  gender?: Maybe<GenderOperationFilterInput>;
  pic?: Maybe<StringOperationFilterInput>;
  firstName?: Maybe<StringOperationFilterInput>;
  lastName?: Maybe<StringOperationFilterInput>;
  country?: Maybe<StringOperationFilterInput>;
  province?: Maybe<StringOperationFilterInput>;
  city?: Maybe<StringOperationFilterInput>;
  zipCode?: Maybe<StringOperationFilterInput>;
  dateOfBirth?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  multitenantEnabled?: Maybe<BooleanOperationFilterInput>;
  clientIds?: Maybe<StringOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  userRoles?: Maybe<ListFilterInputTypeOfUserRoleIdentityFilterInput>;
  id?: Maybe<StringOperationFilterInput>;
  userName?: Maybe<StringOperationFilterInput>;
  normalizedUserName?: Maybe<StringOperationFilterInput>;
  email?: Maybe<StringOperationFilterInput>;
  normalizedEmail?: Maybe<StringOperationFilterInput>;
  emailConfirmed?: Maybe<BooleanOperationFilterInput>;
  passwordHash?: Maybe<StringOperationFilterInput>;
  securityStamp?: Maybe<StringOperationFilterInput>;
  concurrencyStamp?: Maybe<StringOperationFilterInput>;
  phoneNumber?: Maybe<StringOperationFilterInput>;
  phoneNumberConfirmed?: Maybe<BooleanOperationFilterInput>;
  twoFactorEnabled?: Maybe<BooleanOperationFilterInput>;
  lockoutEnd?: Maybe<ComparableNullableOfDateTimeOffsetOperationFilterInput>;
  lockoutEnabled?: Maybe<BooleanOperationFilterInput>;
  accessFailedCount?: Maybe<ComparableInt32OperationFilterInput>;
};

export class UserIdentitySortInput {
  userProfileCompleted?: Maybe<SortEnumType>;
  tenantProfileCompleted?: Maybe<SortEnumType>;
  displayName?: Maybe<SortEnumType>;
  gender?: Maybe<SortEnumType>;
  pic?: Maybe<SortEnumType>;
  firstName?: Maybe<SortEnumType>;
  lastName?: Maybe<SortEnumType>;
  country?: Maybe<SortEnumType>;
  province?: Maybe<SortEnumType>;
  city?: Maybe<SortEnumType>;
  zipCode?: Maybe<SortEnumType>;
  dateOfBirth?: Maybe<SortEnumType>;
  multitenantEnabled?: Maybe<SortEnumType>;
  clientIds?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  userName?: Maybe<SortEnumType>;
  normalizedUserName?: Maybe<SortEnumType>;
  email?: Maybe<SortEnumType>;
  normalizedEmail?: Maybe<SortEnumType>;
  emailConfirmed?: Maybe<SortEnumType>;
  passwordHash?: Maybe<SortEnumType>;
  securityStamp?: Maybe<SortEnumType>;
  concurrencyStamp?: Maybe<SortEnumType>;
  phoneNumber?: Maybe<SortEnumType>;
  phoneNumberConfirmed?: Maybe<SortEnumType>;
  twoFactorEnabled?: Maybe<SortEnumType>;
  lockoutEnd?: Maybe<SortEnumType>;
  lockoutEnabled?: Maybe<SortEnumType>;
  accessFailedCount?: Maybe<SortEnumType>;
};

export class UserIdentityCollectionSegment {
  __typename?: 'UserIdentityCollectionSegment';
  items?: Maybe<Array<Maybe<UserIdentity>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class AppRoleFilterInput {
  and?: Maybe<Array<AppRoleFilterInput>>;
  or?: Maybe<Array<AppRoleFilterInput>>;
  roleCode?: Maybe<StringOperationFilterInput>;
  roleName?: Maybe<StringOperationFilterInput>;
  businessId?: Maybe<ComparableGuidOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  roleTypeId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  roleFeatureMaps?: Maybe<ListFilterInputTypeOfRoleFeatureMapFilterInput>;
  userRoleMaps?: Maybe<ListFilterInputTypeOfUserRoleMapFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class AppRoleSortInput {
  roleCode?: Maybe<SortEnumType>;
  roleName?: Maybe<SortEnumType>;
  businessId?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  roleTypeId?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
};

export class AppRoleCollectionSegment {
  __typename?: 'AppRoleCollectionSegment';
  items?: Maybe<Array<Maybe<AppRole>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export class RoleFeatureDataSortInput {
  featureId?: Maybe<SortEnumType>;
  featureName?: Maybe<SortEnumType>;
  featureParentId?: Maybe<SortEnumType>;
  status?: Maybe<SortEnumType>;
  isRead?: Maybe<SortEnumType>;
  isWrite?: Maybe<SortEnumType>;
};

export class UserRoleMapFilterInput {
  and?: Maybe<Array<UserRoleMapFilterInput>>;
  or?: Maybe<Array<UserRoleMapFilterInput>>;
  userId?: Maybe<StringOperationFilterInput>;
  roleId?: Maybe<ComparableGuidOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  role?: Maybe<AppRoleFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class UserRoleMapSortInput {
  userId?: Maybe<SortEnumType>;
  roleId?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  updatedOn?: Maybe<SortEnumType>;
  updatedById?: Maybe<SortEnumType>;
  role?: Maybe<AppRoleSortInput>;
  id?: Maybe<SortEnumType>;
};

export class TenantFilterInput {
  and?: Maybe<Array<TenantFilterInput>>;
  or?: Maybe<Array<TenantFilterInput>>;
  displayName?: Maybe<StringOperationFilterInput>;
  logo?: Maybe<StringOperationFilterInput>;
  email?: Maybe<StringOperationFilterInput>;
  phone?: Maybe<StringOperationFilterInput>;
  fax?: Maybe<StringOperationFilterInput>;
  mobile?: Maybe<StringOperationFilterInput>;
  tollFree?: Maybe<StringOperationFilterInput>;
  website?: Maybe<StringOperationFilterInput>;
  country?: Maybe<StringOperationFilterInput>;
  currency?: Maybe<StringOperationFilterInput>;
  tenantTypeId?: Maybe<TenantTypesOperationFilterInput>;
  industryId?: Maybe<IndustriesOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<StringOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<StringOperationFilterInput>;
  microsoftTenantId?: Maybe<StringOperationFilterInput>;
  zipCode?: Maybe<StringOperationFilterInput>;
  addressLine1?: Maybe<StringOperationFilterInput>;
  addressLine2?: Maybe<StringOperationFilterInput>;
  city?: Maybe<StringOperationFilterInput>;
  province?: Maybe<StringOperationFilterInput>;
  timeZone?: Maybe<StringOperationFilterInput>;
  userRoles?: Maybe<ListFilterInputTypeOfUserRoleIdentityFilterInput>;
  id?: Maybe<StringOperationFilterInput>;
  canonicalName?: Maybe<StringOperationFilterInput>;
  normalizedCanonicalName?: Maybe<StringOperationFilterInput>;
  concurrencyStamp?: Maybe<StringOperationFilterInput>;
};

export class ListFilterInputTypeOfUserRoleIdentityFilterInput {
  all?: Maybe<UserRoleIdentityFilterInput>;
  none?: Maybe<UserRoleIdentityFilterInput>;
  some?: Maybe<UserRoleIdentityFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class StringOperationFilterInput {
  and?: Maybe<Array<StringOperationFilterInput>>;
  or?: Maybe<Array<StringOperationFilterInput>>;
  eq?: Maybe<Scalars['String']>;
  neq?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  ncontains?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  startsWith?: Maybe<Scalars['String']>;
  nstartsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  nendsWith?: Maybe<Scalars['String']>;
};

export class BooleanOperationFilterInput {
  eq?: Maybe<Scalars['Boolean']>;
  neq?: Maybe<Scalars['Boolean']>;
};

export class GenderOperationFilterInput {
  eq?: Maybe<Gender>;
  neq?: Maybe<Gender>;
  in?: Maybe<Array<Gender>>;
  nin?: Maybe<Array<Gender>>;
};

export class ComparableNullableOfDateTimeOperationFilterInput {
  eq?: Maybe<Scalars['DateTime']>;
  neq?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  nin?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  gt?: Maybe<Scalars['DateTime']>;
  ngt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  ngte?: Maybe<Scalars['DateTime']>;
  lt?: Maybe<Scalars['DateTime']>;
  nlt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  nlte?: Maybe<Scalars['DateTime']>;
};

export class ComparableDateTimeOperationFilterInput {
  eq?: Maybe<Scalars['DateTime']>;
  neq?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  nin?: Maybe<Array<Scalars['DateTime']>>;
  gt?: Maybe<Scalars['DateTime']>;
  ngt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  ngte?: Maybe<Scalars['DateTime']>;
  lt?: Maybe<Scalars['DateTime']>;
  nlt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  nlte?: Maybe<Scalars['DateTime']>;
};

export class ComparableNullableOfDateTimeOffsetOperationFilterInput {
  eq?: Maybe<Scalars['DateTime']>;
  neq?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  nin?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  gt?: Maybe<Scalars['DateTime']>;
  ngt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  ngte?: Maybe<Scalars['DateTime']>;
  lt?: Maybe<Scalars['DateTime']>;
  nlt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  nlte?: Maybe<Scalars['DateTime']>;
};

export class ComparableInt32OperationFilterInput {
  eq?: Maybe<Scalars['Int']>;
  neq?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  nin?: Maybe<Array<Scalars['Int']>>;
  gt?: Maybe<Scalars['Int']>;
  ngt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  ngte?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  nlt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  nlte?: Maybe<Scalars['Int']>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Information about the offset pagination. */
export class CollectionSegmentInfo {
  __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
};

export class ComparableGuidOperationFilterInput {
  eq?: Maybe<Scalars['Uuid']>;
  neq?: Maybe<Scalars['Uuid']>;
  in?: Maybe<Array<Scalars['Uuid']>>;
  nin?: Maybe<Array<Scalars['Uuid']>>;
  gt?: Maybe<Scalars['Uuid']>;
  ngt?: Maybe<Scalars['Uuid']>;
  gte?: Maybe<Scalars['Uuid']>;
  ngte?: Maybe<Scalars['Uuid']>;
  lt?: Maybe<Scalars['Uuid']>;
  nlt?: Maybe<Scalars['Uuid']>;
  lte?: Maybe<Scalars['Uuid']>;
  nlte?: Maybe<Scalars['Uuid']>;
};

export class ComparableNullableOfGuidOperationFilterInput {
  eq?: Maybe<Scalars['Uuid']>;
  neq?: Maybe<Scalars['Uuid']>;
  in?: Maybe<Array<Maybe<Scalars['Uuid']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Uuid']>>>;
  gt?: Maybe<Scalars['Uuid']>;
  ngt?: Maybe<Scalars['Uuid']>;
  gte?: Maybe<Scalars['Uuid']>;
  ngte?: Maybe<Scalars['Uuid']>;
  lt?: Maybe<Scalars['Uuid']>;
  nlt?: Maybe<Scalars['Uuid']>;
  lte?: Maybe<Scalars['Uuid']>;
  nlte?: Maybe<Scalars['Uuid']>;
};

export class ListFilterInputTypeOfRoleFeatureMapFilterInput {
  all?: Maybe<RoleFeatureMapFilterInput>;
  none?: Maybe<RoleFeatureMapFilterInput>;
  some?: Maybe<RoleFeatureMapFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class ListFilterInputTypeOfUserRoleMapFilterInput {
  all?: Maybe<UserRoleMapFilterInput>;
  none?: Maybe<UserRoleMapFilterInput>;
  some?: Maybe<UserRoleMapFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export class TenantTypesOperationFilterInput {
  eq?: Maybe<TenantTypes>;
  neq?: Maybe<TenantTypes>;
  in?: Maybe<Array<TenantTypes>>;
  nin?: Maybe<Array<TenantTypes>>;
};

export class IndustriesOperationFilterInput {
  eq?: Maybe<Industries>;
  neq?: Maybe<Industries>;
  in?: Maybe<Array<Industries>>;
  nin?: Maybe<Array<Industries>>;
};

export class StatesOperationFilterInput {
  eq?: Maybe<States>;
  neq?: Maybe<States>;
  in?: Maybe<Array<States>>;
  nin?: Maybe<Array<States>>;
};

export class UserRoleIdentityFilterInput {
  and?: Maybe<Array<UserRoleIdentityFilterInput>>;
  or?: Maybe<Array<UserRoleIdentityFilterInput>>;
  tenantId?: Maybe<StringOperationFilterInput>;
  state?: Maybe<StatesOperationFilterInput>;
  tenant?: Maybe<TenantFilterInput>;
  user?: Maybe<UserIdentityFilterInput>;
  role?: Maybe<RoleIdentityFilterInput>;
  userId?: Maybe<StringOperationFilterInput>;
  roleId?: Maybe<StringOperationFilterInput>;
};

export class RoleFeatureMapFilterInput {
  and?: Maybe<Array<RoleFeatureMapFilterInput>>;
  or?: Maybe<Array<RoleFeatureMapFilterInput>>;
  roleId?: Maybe<ComparableGuidOperationFilterInput>;
  featureId?: Maybe<ComparableGuidOperationFilterInput>;
  isRead?: Maybe<BooleanOperationFilterInput>;
  isWrite?: Maybe<BooleanOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  role?: Maybe<AppRoleFilterInput>;
  feature?: Maybe<FeatureFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class FeatureFilterInput {
  and?: Maybe<Array<FeatureFilterInput>>;
  or?: Maybe<Array<FeatureFilterInput>>;
  featureCode?: Maybe<StringOperationFilterInput>;
  featureName?: Maybe<StringOperationFilterInput>;
  featureParentId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  updatedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedById?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  featureParent?: Maybe<FeatureFilterInput>;
  roleFeatureMaps?: Maybe<ListFilterInputTypeOfRoleFeatureMapFilterInput>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
};

export class CreateBusinessVmInput {
  ownerId: Scalars['Uuid'];
  name: Scalars['String'];
  businessTypeId: Scalars['Byte'];
  country: Scalars['String'];
  currency: Scalars['String'];
  industryId: Scalars['Byte'];
};

export class UpdateBusinessVmInput {
  ownerId: Scalars['Uuid'];
  id: Scalars['Uuid'];
  name: Scalars['String'];
  phone: Scalars['String'];
  mobile?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  tollFree?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  currency: Scalars['String'];
  province?: Maybe<Scalars['String']>;
  addressLine1: Scalars['String'];
  country: Scalars['String'];
  timeZone: Scalars['String'];
};

export class AssignUserRolesInput {
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  roles: Array<Maybe<Scalars['String']>>;
};

export class CreateUserInput {
  adminId: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
};

export class CreateProjectClientInput {
  clientIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  adminId: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
};

export class ProjectClientOwnersInput {
  adminId: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  clientId?: Maybe<Scalars['String']>;
  addUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  removeUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export class UpdateUserInput {
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export class CreateAppRoleVmInput {
  roleName: Scalars['String'];
  roleCode: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  businessId: Scalars['Uuid'];
  createdOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedOn: Scalars['DateTime'];
  updatedById?: Maybe<Scalars['Uuid']>;
};

export class RoleFeatureVmInput {
  roleId?: Maybe<Scalars['Uuid']>;
  featureId: Scalars['Uuid'];
  isRead: Scalars['Boolean'];
  isWrite: Scalars['Boolean'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
};

export class UserRoleVmInput {
  userId?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['Uuid']>;
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
};

export class UpdateAppRoleVmInput {
  id: Scalars['Uuid'];
  roleName: Scalars['String'];
  roleCode: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  businessId: Scalars['Uuid'];
  createdOn: Scalars['DateTime'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedOn: Scalars['DateTime'];
  updatedById?: Maybe<Scalars['Uuid']>;
};

export class UpdateRoleFeatureVmInput {
  id: Scalars['Uuid'];
  roleId?: Maybe<Scalars['Uuid']>;
  featureId: Scalars['Uuid'];
  isRead: Scalars['Boolean'];
  isWrite: Scalars['Boolean'];
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
};

export class UpdateUserRoleVmInput {
  id: Scalars['Uuid'];
  userId?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['Uuid']>;
  createdById?: Maybe<Scalars['Uuid']>;
  updatedById?: Maybe<Scalars['Uuid']>;
};

export class UpdateProfileInput {
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  pic?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export class UpdatePasswordInput {
  email: Scalars['String'];
  id: Scalars['Uuid'];
  businessId: Scalars['Uuid'];
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type GetMyBusinessesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyBusinessesQuery = { session?: Maybe<(
    { __typename?: 'SessionQueryType' }
    & { businesses?: Maybe<(
      { __typename?: 'BusinessQueryType' }
      & { associatedBusinesses?: Maybe<Array<Maybe<(
        { __typename?: 'Business' }
        & Pick<Business, 'id' | 'name' | 'state' | 'isOwned'>
      )>>> }
    )> }
  )> };

export type GetMyBusinessByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetMyBusinessByIdQuery = { session?: Maybe<(
    { __typename?: 'SessionQueryType' }
    & { businesses?: Maybe<(
      { __typename?: 'BusinessQueryType' }
      & { associatedBusinesses?: Maybe<Array<Maybe<(
        { __typename?: 'Business' }
        & Pick<Business, 'id' | 'name' | 'fax' | 'mobile' | 'phone' | 'tollFree' | 'website' | 'country' | 'currency' | 'state' | 'addressLine1' | 'addressLine2' | 'zipCode' | 'city' | 'province'>
      )>>> }
    )> }
  )> };

export type GetMyActiveBusinessesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyActiveBusinessesQuery = { session?: Maybe<(
    { __typename?: 'SessionQueryType' }
    & { businesses?: Maybe<(
      { __typename?: 'BusinessQueryType' }
      & { associatedBusinesses?: Maybe<Array<Maybe<(
        { __typename?: 'Business' }
        & Pick<Business, 'id' | 'name' | 'state'>
      )>>> }
    )> }
  )> };

export type CreateBusinessMutationVariables = Exact<{
  model: CreateBusinessVmInput;
}>;


export type CreateBusinessMutation = { businesses?: Maybe<(
    { __typename?: 'BusinessMutationType' }
    & Pick<BusinessMutationType, 'create'>
  )> };

export type UpdateBusinessMutationVariables = Exact<{
  model?: Maybe<UpdateBusinessVmInput>;
}>;


export type UpdateBusinessMutation = { businesses?: Maybe<(
    { __typename?: 'BusinessMutationType' }
    & Pick<BusinessMutationType, 'update'>
  )> };

export type ArchiveBusinessMutationVariables = Exact<{
  businessId: Scalars['Uuid'];
}>;


export type ArchiveBusinessMutation = { businesses?: Maybe<(
    { __typename?: 'BusinessMutationType' }
    & Pick<BusinessMutationType, 'archive'>
  )> };

export type RestoreBusinessMutationVariables = Exact<{
  businessId: Scalars['Uuid'];
}>;


export type RestoreBusinessMutation = { businesses?: Maybe<(
    { __typename?: 'BusinessMutationType' }
    & Pick<BusinessMutationType, 'restore'>
  )> };

export type GetMyProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyProfileQuery = { session?: Maybe<(
    { __typename?: 'SessionQueryType' }
    & { me?: Maybe<(
      { __typename?: 'UserIdentity' }
      & Pick<UserIdentity, 'firstName' | 'lastName' | 'email' | 'pic' | 'country' | 'province' | 'zipCode' | 'city' | 'dateOfBirth'>
    )> }
  )> };

export type UpdateMyProfileMutationVariables = Exact<{
  model: UpdateProfileInput;
}>;


export type UpdateMyProfileMutation = { session?: Maybe<(
    { __typename?: 'SessionMutationType' }
    & { me?: Maybe<(
      { __typename?: 'ProfileMutationType' }
      & Pick<ProfileMutationType, 'update'>
    )> }
  )> };

export type UpdatePasswordMutationVariables = Exact<{
  model?: Maybe<UpdatePasswordInput>;
}>;


export type UpdatePasswordMutation = { session?: Maybe<(
    { __typename?: 'SessionMutationType' }
    & { me?: Maybe<(
      { __typename?: 'ProfileMutationType' }
      & Pick<ProfileMutationType, 'updatePassword'>
    )> }
  )> };

export type CloseAccountMutationVariables = Exact<{ [key: string]: never; }>;


export type CloseAccountMutation = { session?: Maybe<(
    { __typename?: 'SessionMutationType' }
    & { me?: Maybe<(
      { __typename?: 'ProfileMutationType' }
      & Pick<ProfileMutationType, 'closeAccount'>
    )> }
  )> };

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = { session?: Maybe<(
    { __typename?: 'SessionMutationType' }
    & { me?: Maybe<(
      { __typename?: 'ProfileMutationType' }
      & Pick<ProfileMutationType, 'forgotPassword'>
    )> }
  )> };

export type PaginatedRoleFeatureMapQueryVariables = Exact<{
  roleId?: Maybe<Scalars['Uuid']>;
}>;


export type PaginatedRoleFeatureMapQuery = { roleFeatureMap?: Maybe<(
    { __typename?: 'RoleFeatureMapQueryType' }
    & { paginatedRoleFeatureMap?: Maybe<Array<Maybe<(
      { __typename?: 'RoleFeatureData' }
      & Pick<RoleFeatureData, 'featureId' | 'featureName' | 'status' | 'isRead' | 'isWrite' | 'featureParentId'>
    )>>> }
  )> };

export type GetrolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetrolesQuery = { appRoles?: Maybe<(
    { __typename?: 'AppRoleQueryType' }
    & { paginatedRoles?: Maybe<(
      { __typename?: 'AppRoleCollectionSegment' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'AppRole' }
        & Pick<AppRole, 'businessId' | 'roleCode' | 'roleName' | 'roleTypeId' | 'description' | 'id'>
      )>>> }
    )> }
  )> };

export type GetPagenatedRolesQueryVariables = Exact<{
  filter?: Maybe<AppRoleFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPagenatedRolesQuery = { appRoles?: Maybe<(
    { __typename?: 'AppRoleQueryType' }
    & { paginatedRoles?: Maybe<(
      { __typename?: 'AppRoleCollectionSegment' }
      & Pick<AppRoleCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'AppRole' }
        & Pick<AppRole, 'businessId' | 'roleCode' | 'roleName' | 'roleTypeId' | 'description' | 'id'>
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & Pick<CollectionSegmentInfo, 'hasPreviousPage' | 'hasNextPage'>
      ) }
    )> }
  )> };

export type GetRoleByIdQueryVariables = Exact<{
  roleId: Scalars['Uuid'];
}>;


export type GetRoleByIdQuery = { appRoles?: Maybe<(
    { __typename?: 'AppRoleQueryType' }
    & { roles?: Maybe<Array<Maybe<(
      { __typename?: 'AppRole' }
      & Pick<AppRole, 'businessId' | 'roleCode' | 'roleName' | 'description' | 'id' | 'roleTypeId'>
    )>>> }
  )> };

export type CreateAppRoleMutationVariables = Exact<{
  model?: Maybe<CreateAppRoleVmInput>;
  modelF?: Maybe<Array<Maybe<RoleFeatureVmInput>> | Maybe<RoleFeatureVmInput>>;
  modelU?: Maybe<Array<Maybe<UserRoleVmInput>> | Maybe<UserRoleVmInput>>;
}>;


export type CreateAppRoleMutation = { appRoles?: Maybe<(
    { __typename?: 'AppRoleMutationType' }
    & Pick<AppRoleMutationType, 'create'>
  )> };

export type UpdateAppRoleMutationVariables = Exact<{
  model?: Maybe<UpdateAppRoleVmInput>;
  modelF?: Maybe<Array<Maybe<UpdateRoleFeatureVmInput>> | Maybe<UpdateRoleFeatureVmInput>>;
  modelU?: Maybe<Array<Maybe<UpdateUserRoleVmInput>> | Maybe<UpdateUserRoleVmInput>>;
}>;


export type UpdateAppRoleMutation = { appRoles?: Maybe<(
    { __typename?: 'AppRoleMutationType' }
    & Pick<AppRoleMutationType, 'update'>
  )> };

export type GetAllAssignedUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllAssignedUsersQuery = { userRoleMap?: Maybe<(
    { __typename?: 'UserRoleMapQueryType' }
    & { userRoles?: Maybe<Array<Maybe<(
      { __typename?: 'UserRoleMap' }
      & Pick<UserRoleMap, 'id' | 'roleId' | 'userId' | 'createdOn' | 'updatedOn'>
    )>>> }
  )> };

export type GetUsersByRoleIdQueryVariables = Exact<{
  roleId: Scalars['Uuid'];
}>;


export type GetUsersByRoleIdQuery = { userRoleMap?: Maybe<(
    { __typename?: 'UserRoleMapQueryType' }
    & { userRoles?: Maybe<Array<Maybe<(
      { __typename?: 'UserRoleMap' }
      & Pick<UserRoleMap, 'id' | 'roleId' | 'userId' | 'createdOn' | 'updatedOn'>
    )>>> }
  )> };

export type GetPaginatedUsersQueryVariables = Exact<{
  filter?: Maybe<UserIdentityFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  states?: Maybe<Array<States> | States>;
}>;


export type GetPaginatedUsersQuery = { users?: Maybe<(
    { __typename?: 'UserQueryType' }
    & { paginated?: Maybe<(
      { __typename?: 'UserIdentityCollectionSegment' }
      & Pick<UserIdentityCollectionSegment, 'totalCount'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'UserIdentity' }
        & Pick<UserIdentity, 'id' | 'firstName' | 'lastName' | 'displayName' | 'email' | 'state' | 'pic' | 'dateOfBirth'>
      )>>>, pageInfo: (
        { __typename?: 'CollectionSegmentInfo' }
        & Pick<CollectionSegmentInfo, 'hasPreviousPage' | 'hasNextPage'>
      ) }
    )> }
  )> };

export type GetUserRolesQueryVariables = Exact<{
  userId: Scalars['Uuid'];
}>;


export type GetUserRolesQuery = { users?: Maybe<(
    { __typename?: 'UserQueryType' }
    & { userRoles?: Maybe<Array<Maybe<(
      { __typename?: 'RoleIdentity' }
      & Pick<RoleIdentity, 'id' | 'name'>
    )>>> }
  )> };

export type GetUserQueryVariables = Exact<{
  userId: Scalars['Uuid'];
}>;


export type GetUserQuery = { users?: Maybe<(
    { __typename?: 'UserQueryType' }
    & { first?: Maybe<(
      { __typename?: 'UserIdentity' }
      & Pick<UserIdentity, 'id' | 'firstName' | 'lastName' | 'displayName' | 'email' | 'state' | 'pic' | 'dateOfBirth'>
    )> }
  )> };

export type ArchiveUserMutationVariables = Exact<{
  userId: Scalars['Uuid'];
}>;


export type ArchiveUserMutation = { users?: Maybe<(
    { __typename?: 'UserMutationType' }
    & Pick<UserMutationType, 'archive'>
  )> };

export type RestoreUserMutationVariables = Exact<{
  userId: Scalars['Uuid'];
}>;


export type RestoreUserMutation = { users?: Maybe<(
    { __typename?: 'UserMutationType' }
    & Pick<UserMutationType, 'restore'>
  )> };

export type UpdateUserMutationVariables = Exact<{
  model?: Maybe<UpdateUserInput>;
}>;


export type UpdateUserMutation = { users?: Maybe<(
    { __typename?: 'UserMutationType' }
    & Pick<UserMutationType, 'update'>
  )> };

export type CreateUserMutationVariables = Exact<{
  model: CreateUserInput;
}>;


export type CreateUserMutation = { users?: Maybe<(
    { __typename?: 'UserMutationType' }
    & Pick<UserMutationType, 'add'>
  )> };

export type CreateClientOwnerUserMutationVariables = Exact<{
  model: CreateProjectClientInput;
}>;


export type CreateClientOwnerUserMutation = { users?: Maybe<(
    { __typename?: 'UserMutationType' }
    & Pick<UserMutationType, 'createProjectClientUser'>
  )> };

export type CreateClientOwnersMutationVariables = Exact<{
  model?: Maybe<ProjectClientOwnersInput>;
}>;


export type CreateClientOwnersMutation = { users?: Maybe<(
    { __typename?: 'UserMutationType' }
    & Pick<UserMutationType, 'addRemoveProjectClientOwners'>
  )> };

export type AssignRolesToUserMutationVariables = Exact<{
  model?: Maybe<AssignUserRolesInput>;
}>;


export type AssignRolesToUserMutation = { users?: Maybe<(
    { __typename?: 'UserMutationType' }
    & Pick<UserMutationType, 'assignRolesToUser'>
  )> };

export type SyncActiveDirectoryUsersMutationVariables = Exact<{ [key: string]: never; }>;


export type SyncActiveDirectoryUsersMutation = { users?: Maybe<(
    { __typename?: 'UserMutationType' }
    & Pick<UserMutationType, 'syncActiveDirectoryUsers'>
  )> };

export type SyncSingleUserMutationVariables = Exact<{
  userId: Scalars['Uuid'];
}>;


export type SyncSingleUserMutation = { users?: Maybe<(
    { __typename?: 'UserMutationType' }
    & Pick<UserMutationType, 'syncSingleUser'>
  )> };

export const GetMyBusinessesDocument = gql`
    query getMyBusinesses {
  session {
    businesses {
      associatedBusinesses {
        id
        name
        state
        isOwned
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMyBusinessesGQL extends Apollo.Query<GetMyBusinessesQuery, GetMyBusinessesQueryVariables> {
    document = GetMyBusinessesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMyBusinessByIdDocument = gql`
    query GetMyBusinessById($id: String!) {
  session {
    businesses {
      associatedBusinesses(where: {id: {eq: $id}}) {
        id
        name
        fax
        mobile
        phone
        tollFree
        website
        country
        currency
        state
        addressLine1
        addressLine2
        zipCode
        city
        province
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMyBusinessByIdGQL extends Apollo.Query<GetMyBusinessByIdQuery, GetMyBusinessByIdQueryVariables> {
    document = GetMyBusinessByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMyActiveBusinessesDocument = gql`
    query getMyActiveBusinesses {
  session {
    businesses {
      associatedBusinesses(where: {state: {eq: ACTIVE}}) {
        id
        name
        state
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMyActiveBusinessesGQL extends Apollo.Query<GetMyActiveBusinessesQuery, GetMyActiveBusinessesQueryVariables> {
    document = GetMyActiveBusinessesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateBusinessDocument = gql`
    mutation createBusiness($model: CreateBusinessVmInput!) {
  businesses {
    create(model: $model)
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateBusinessGQL extends Apollo.Mutation<CreateBusinessMutation, CreateBusinessMutationVariables> {
    document = CreateBusinessDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBusinessDocument = gql`
    mutation updateBusiness($model: UpdateBusinessVmInput) {
  businesses {
    update(model: $model)
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBusinessGQL extends Apollo.Mutation<UpdateBusinessMutation, UpdateBusinessMutationVariables> {
    document = UpdateBusinessDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveBusinessDocument = gql`
    mutation archiveBusiness($businessId: Uuid!) {
  businesses {
    archive(businessId: $businessId)
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveBusinessGQL extends Apollo.Mutation<ArchiveBusinessMutation, ArchiveBusinessMutationVariables> {
    document = ArchiveBusinessDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestoreBusinessDocument = gql`
    mutation restoreBusiness($businessId: Uuid!) {
  businesses {
    restore(businessId: $businessId)
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RestoreBusinessGQL extends Apollo.Mutation<RestoreBusinessMutation, RestoreBusinessMutationVariables> {
    document = RestoreBusinessDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMyProfileDocument = gql`
    query getMyProfile {
  session {
    me {
      firstName
      lastName
      email
      pic
      country
      province
      zipCode
      city
      dateOfBirth
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMyProfileGQL extends Apollo.Query<GetMyProfileQuery, GetMyProfileQueryVariables> {
    document = GetMyProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateMyProfileDocument = gql`
    mutation updateMyProfile($model: UpdateProfileInput!) {
  session {
    me {
      update(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateMyProfileGQL extends Apollo.Mutation<UpdateMyProfileMutation, UpdateMyProfileMutationVariables> {
    document = UpdateMyProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePasswordDocument = gql`
    mutation updatePassword($model: UpdatePasswordInput) {
  session {
    me {
      updatePassword(model: $model)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePasswordGQL extends Apollo.Mutation<UpdatePasswordMutation, UpdatePasswordMutationVariables> {
    document = UpdatePasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CloseAccountDocument = gql`
    mutation closeAccount {
  session {
    me {
      closeAccount
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CloseAccountGQL extends Apollo.Mutation<CloseAccountMutation, CloseAccountMutationVariables> {
    document = CloseAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  session {
    me {
      forgotPassword(email: $email)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ForgotPasswordGQL extends Apollo.Mutation<ForgotPasswordMutation, ForgotPasswordMutationVariables> {
    document = ForgotPasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaginatedRoleFeatureMapDocument = gql`
    query paginatedRoleFeatureMap($roleId: Uuid) {
  roleFeatureMap {
    paginatedRoleFeatureMap(roleId: $roleId) {
      featureId
      featureName
      status
      isRead
      isWrite
      featureParentId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PaginatedRoleFeatureMapGQL extends Apollo.Query<PaginatedRoleFeatureMapQuery, PaginatedRoleFeatureMapQueryVariables> {
    document = PaginatedRoleFeatureMapDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetrolesDocument = gql`
    query getroles {
  appRoles {
    paginatedRoles {
      items {
        businessId
        roleCode
        roleName
        roleTypeId
        description
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetrolesGQL extends Apollo.Query<GetrolesQuery, GetrolesQueryVariables> {
    document = GetrolesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPagenatedRolesDocument = gql`
    query getPagenatedRoles($filter: AppRoleFilterInput, $skip: Int, $take: Int) {
  appRoles {
    paginatedRoles(
      skip: $skip
      take: $take
      where: $filter
      order: [{roleName: ASC}]
    ) {
      items {
        businessId
        roleCode
        roleName
        roleTypeId
        description
        id
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      totalCount
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPagenatedRolesGQL extends Apollo.Query<GetPagenatedRolesQuery, GetPagenatedRolesQueryVariables> {
    document = GetPagenatedRolesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetRoleByIdDocument = gql`
    query getRoleById($roleId: Uuid!) {
  appRoles {
    roles(where: {and: [{id: {eq: $roleId}}]}) {
      businessId
      roleCode
      roleName
      description
      id
      roleTypeId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetRoleByIdGQL extends Apollo.Query<GetRoleByIdQuery, GetRoleByIdQueryVariables> {
    document = GetRoleByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateAppRoleDocument = gql`
    mutation createAppRole($model: CreateAppRoleVmInput, $modelF: [RoleFeatureVmInput], $modelU: [UserRoleVmInput]) {
  appRoles {
    create(model: $model, modelF: $modelF, modelU: $modelU)
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateAppRoleGQL extends Apollo.Mutation<CreateAppRoleMutation, CreateAppRoleMutationVariables> {
    document = CreateAppRoleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAppRoleDocument = gql`
    mutation updateAppRole($model: UpdateAppRoleVmInput, $modelF: [UpdateRoleFeatureVmInput], $modelU: [UpdateUserRoleVmInput]) {
  appRoles {
    update(model: $model, modelF: $modelF, modelU: $modelU)
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAppRoleGQL extends Apollo.Mutation<UpdateAppRoleMutation, UpdateAppRoleMutationVariables> {
    document = UpdateAppRoleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllAssignedUsersDocument = gql`
    query getAllAssignedUsers {
  userRoleMap {
    userRoles {
      id
      roleId
      userId
      createdOn
      updatedOn
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllAssignedUsersGQL extends Apollo.Query<GetAllAssignedUsersQuery, GetAllAssignedUsersQueryVariables> {
    document = GetAllAssignedUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUsersByRoleIdDocument = gql`
    query getUsersByRoleId($roleId: Uuid!) {
  userRoleMap {
    userRoles(where: {and: [{roleId: {eq: $roleId}}]}) {
      id
      roleId
      userId
      createdOn
      updatedOn
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUsersByRoleIdGQL extends Apollo.Query<GetUsersByRoleIdQuery, GetUsersByRoleIdQueryVariables> {
    document = GetUsersByRoleIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaginatedUsersDocument = gql`
    query getPaginatedUsers($filter: UserIdentityFilterInput, $skip: Int, $take: Int, $states: [States!]) {
  users {
    paginated(
      skip: $skip
      take: $take
      where: $filter
      order: [{displayName: ASC}]
      states: $states
    ) {
      items {
        id
        firstName
        lastName
        displayName
        email
        state
        pic
        dateOfBirth
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      totalCount
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaginatedUsersGQL extends Apollo.Query<GetPaginatedUsersQuery, GetPaginatedUsersQueryVariables> {
    document = GetPaginatedUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUserRolesDocument = gql`
    query getUserRoles($userId: Uuid!) {
  users {
    userRoles(userId: $userId) {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserRolesGQL extends Apollo.Query<GetUserRolesQuery, GetUserRolesQueryVariables> {
    document = GetUserRolesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUserDocument = gql`
    query getUser($userId: Uuid!) {
  users {
    first(userId: $userId) {
      id
      firstName
      lastName
      displayName
      email
      state
      pic
      dateOfBirth
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserGQL extends Apollo.Query<GetUserQuery, GetUserQueryVariables> {
    document = GetUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveUserDocument = gql`
    mutation archiveUser($userId: Uuid!) {
  users {
    archive(userId: $userId)
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveUserGQL extends Apollo.Mutation<ArchiveUserMutation, ArchiveUserMutationVariables> {
    document = ArchiveUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestoreUserDocument = gql`
    mutation restoreUser($userId: Uuid!) {
  users {
    restore(userId: $userId)
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RestoreUserGQL extends Apollo.Mutation<RestoreUserMutation, RestoreUserMutationVariables> {
    document = RestoreUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserDocument = gql`
    mutation updateUser($model: UpdateUserInput) {
  users {
    update(model: $model)
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserGQL extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
    document = UpdateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserDocument = gql`
    mutation createUser($model: CreateUserInput!) {
  users {
    add(model: $model)
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserGQL extends Apollo.Mutation<CreateUserMutation, CreateUserMutationVariables> {
    document = CreateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateClientOwnerUserDocument = gql`
    mutation createClientOwnerUser($model: CreateProjectClientInput!) {
  users {
    createProjectClientUser(model: $model)
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateClientOwnerUserGQL extends Apollo.Mutation<CreateClientOwnerUserMutation, CreateClientOwnerUserMutationVariables> {
    document = CreateClientOwnerUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateClientOwnersDocument = gql`
    mutation createClientOwners($model: ProjectClientOwnersInput) {
  users {
    addRemoveProjectClientOwners(model: $model)
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateClientOwnersGQL extends Apollo.Mutation<CreateClientOwnersMutation, CreateClientOwnersMutationVariables> {
    document = CreateClientOwnersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AssignRolesToUserDocument = gql`
    mutation assignRolesToUser($model: AssignUserRolesInput) {
  users {
    assignRolesToUser(model: $model)
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssignRolesToUserGQL extends Apollo.Mutation<AssignRolesToUserMutation, AssignRolesToUserMutationVariables> {
    document = AssignRolesToUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SyncActiveDirectoryUsersDocument = gql`
    mutation syncActiveDirectoryUsers {
  users {
    syncActiveDirectoryUsers
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SyncActiveDirectoryUsersGQL extends Apollo.Mutation<SyncActiveDirectoryUsersMutation, SyncActiveDirectoryUsersMutationVariables> {
    document = SyncActiveDirectoryUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SyncSingleUserDocument = gql`
    mutation syncSingleUser($userId: Uuid!) {
  users {
    syncSingleUser(userId: $userId)
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SyncSingleUserGQL extends Apollo.Mutation<SyncSingleUserMutation, SyncSingleUserMutationVariables> {
    document = SyncSingleUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }