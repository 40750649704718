export const environment = {
  production: false,
  qa: true,
  hmr: false,
  dev: false,
  wsUrl: 'wss://api.stg.doplaner.com',
  apiUrl: 'https://api.stg.doplaner.com',
  authUrl: 'https://auth.stg.doplaner.com',
  iframeHost: 'https://reports.stg.doplaner.com',
  //ssrsHost: 'https://report.ssrs.stg.quickanalytixqa.nl',
  ssrsHost: 'https://stg.report.ssrs.doplaner.com',
  FinanceReportsPath: 'Finance',
  HrReportsPath: 'HR',
  SupportReportsPath: 'Support',
  TimeTrackingReportsPath: 'TimeTracking',
  SSRSReportPath: 'Report/ReportTemplate?ReportPath=DoPlaner/', //Report Project
  version: '3.0.3',
  // Auth
  ResourceServer: 'https://auth.stg.doplaner.com/',
  IssuerUri: 'https://auth.stg.doplaner.com',
  // Uri: 'http://finance.quickanalytixqa.nl',
  Uri: 'https://portal.stg.doplaner.com',
  RequireHttps: false,
  // Metronic
  appVersion: 'v711demo1',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
};
